import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "1BB4B8",
        secondary: "114B5F",
        accent: "4DA986",
      },
    },
  },
});
