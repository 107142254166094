
import Vue from "vue";

export default Vue.extend({
  name: "App",

  components: {
    CoreAppBar: () => import("@/components/core/AppBar.vue"),
    CoreFooter: () => import("@/components/core/Footer.vue"),
    CoreView: () => import("@/components/core/View.vue"),
    CoreFloatingBtn: () => import("@/components/core/FloatingBtn.vue"),
  },
});
