<template>
  <component
    :is="tag"
    class="text-center text-h3 mb-3 v-heading font-weight-bold"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: "BaseHeading",

  props: {
    tag: {
      type: String,
      default: "h1",
    },
  },
};
</script>

<style lang="sass">
.v-heading
  letter-spacing: 3px !important
</style>
