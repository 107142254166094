import Vue from "vue";
import BaseBtn from "@/components/base/Btn.vue";
import BaseHeading from "@/components/base/Heading.vue";
import BaseSubheading from "@/components/base/Subheading.vue";
import BaseText from "@/components/base/Text.vue";

Vue.component("BaseBtn", BaseBtn);
Vue.component("BaseHeading", BaseHeading);
Vue.component("BaseSubheading", BaseSubheading);
Vue.component("BaseText", BaseText);
