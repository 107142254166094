<template>
  <v-btn
    color="primary"
    target="_blank"
    rel="noopener noreferrer"
    depressed
    rounded
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-btn>
</template>

<script>
export default {
  name: "BaseBtn",
};
</script>
